<template>
  <div>
    <b-table-custom
        ref="tableData"
        :table-data="dataObjectList"
        :table-columns="tableColumns"
        :is-data-loading="isDataLoading"
        @checkOperationStatus="checkOperationStatus"
        @menuClick="cloudVpsMenuClick"
        @selected-item-onchange="selectedItemOnChange"
    >
      <template #menu="data">
        <!--update vps-->
        <b-dropdown-item v-b-modal:modal-update-vps>
          <font-awesome-icon icon="fa-solid fa-edit" class="text-info" />
          <span>{{ $t('cloudVps.table.button.update') }}</span>
        </b-dropdown-item>
        <!--Addons-->
        <b-dropdown-item @click="modalAddonOpen">
          <font-awesome-icon :icon="['fas', 'memory']" class="text-success" />
          <span>{{ $t('cloudVps.table.button.addons') }}</span>
        </b-dropdown-item>
        <b-dropdown-divider />
        <!--start vps-->
        <b-dropdown-item @click="startVPS(data['item'])">
          <font-awesome-icon icon="fa-solid fa-play" class="text-success" />
          <span>{{ $t('cloudVps.table.button.start') }}</span>
        </b-dropdown-item>
        <!--stop vps-->
        <b-dropdown-item @click="stopVPS(data['item'])">
          <font-awesome-icon icon="fa-solid fa-power-off" class="text-danger" />
          <span>{{ $t('cloudVps.table.button.stop') }}</span>
        </b-dropdown-item>
        <!--restart vps-->
        <b-dropdown-item @click="restartVPS(data['item'])">
          <font-awesome-icon icon="fa-solid fa-arrows-rotate" class="text-danger" />
          <span>{{ $t('cloudVps.table.button.restart') }}</span>
        </b-dropdown-item>
        <!--reinstall os vps-->
        <b-dropdown-item disabled>
          <font-awesome-icon icon="fa-brands fa-windows" class="text-danger" />
          <span>{{ $t('cloudVps.table.button.reinstall_os') }}</span>
        </b-dropdown-item>
        <b-dropdown-divider />
        <!--create snapshot vps-->
        <b-dropdown-item disabled>
          <font-awesome-icon icon="fa-solid fa-cloud-arrow-up" class="text-success" />
          <span>{{ $t('cloudVps.table.button.create_snapshot') }}</span>
        </b-dropdown-item>
        <!--restore snapshot vps-->
        <b-dropdown-item disabled>
          <font-awesome-icon icon="fa-solid fa-cloud-arrow-down" class="text-danger" />
          <span>{{ $t('cloudVps.table.button.restore_snapshot') }}</span>
        </b-dropdown-item>
        <b-dropdown-divider />
        <!--enable auto backup vps-->
        <b-dropdown-item disabled>
          <font-awesome-icon icon="fa-solid fa-hard-drive" class="text-success" />
          <span>{{ $t('cloudVps.table.button.enable_auto_backup') }}</span>
        </b-dropdown-item>
        <!--disable auto backup vps-->
        <b-dropdown-item disabled hidden>
          <font-awesome-icon icon="fa-solid fa-file-circle-exclamation" class="text-warning" />
          <span>{{ $t('cloudVps.table.button.disable_auto_backup') }}</span>
        </b-dropdown-item>
        <!--restore backup vps-->
        <b-dropdown-item disabled>
          <font-awesome-icon icon="fa-solid fa-retweet" class="text-danger" />
          <span>{{ $t('cloudVps.table.button.restore_backup') }}</span>
        </b-dropdown-item>
        <b-dropdown-divider />
        <!--cancel vps-->
        <b-dropdown-item disabled>
          <font-awesome-icon icon="fa-solid fa-ban" class="text-secondary" />
          <span>{{ $t('cloudVps.table.button.cancel') }}</span>
        </b-dropdown-item>
        <!--renew vps-->
        <b-dropdown-item disabled>
          <font-awesome-icon icon="fa-solid fa-calendar-plus" class="text-info" />
          <span>{{ $t('cloudVps.table.button.renew') }}</span>
        </b-dropdown-item>
        <!--auto renew vps-->
        <b-dropdown-item disabled>
          <font-awesome-icon icon="fa-solid fa-calendar-check" class="text-info" />
          <span>{{ $t('cloudVps.table.button.autoRenew') }}</span>
        </b-dropdown-item>
        <b-dropdown-divider />
        <!--active vps-->
        <b-dropdown-item v-if="data.item['is_suspended']" @click="activeCloudVps(data['item'])">
          <font-awesome-icon icon="fa-solid fa-lock-open" class="text-success" />
          <span>{{ $t('cloudVps.table.button.active') }}</span>
        </b-dropdown-item>
        <!--suspended vps-->
        <b-dropdown-item v-else @click="suspendCloudVps(data['item'])">
          <font-awesome-icon icon="fa-solid fa-ban" class="text-danger" />
          <span>{{ $t('cloudVps.table.button.suspended') }}</span>
        </b-dropdown-item>
        <!--delete vps-->
        <b-dropdown-item @click="deleteCloudVps(data['item'])">
          <font-awesome-icon icon="fa-solid fa-trash" class="text-danger" />
          <span>{{ $t('cloudVps.table.button.delete') }}</span>
        </b-dropdown-item>
      </template>
      <template #button_event>
        <b-dropdown class="menu-button"
                    variant="primary"
                    size="sm"
        >
          <template #button-content>
            <feather-icon icon="SettingsIcon" class="mr-1"/>
            <span>Action Menu</span>
          </template>
          <template v-for="(menuItem, index) in actionMenuList">
            <b-dropdown-divider v-if="menuItem['type'] === 'divider'" :key="index"/>
            <b-dropdown-item v-else
                             :key="index"
                             @click="openModal(menuItem['name'])"
                             :disabled="menuItem['disabled']"
            >
              <font-awesome-icon :icon="menuItem['icon']" :class="`text-${menuItem['color']}`" />
              <span>{{ menuItem['label'] }}</span>
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </template>

      <template #cell(ip_port)="{data}">
        <div class="font-weight-bold text-primary d-flex justify-content-between align-items-center">
          <span>{{ data['item']['ip_port'] }}</span>
          <i v-if="data['item']['addon'].length > 0"
             class="fas fa-puzzle-piece cursor-pointer text-success ml-1"
             v-b-tooltip.hover.v-success
             title="Addon"
             @click="data['toggleDetails']"/>
        </div>
        <div class="text-success" v-if="data['item']['rgc']['server_name'] !== null">{{`${data['item']['rgc']['server_name']}:${data['item']['rgc']['forwarded_port']}`}}</div>
      </template>
      <template #cell(bandwidth)="{data}">
        <progress-bar :value="data['value']['total_rx'] + data['value']['total_tx']" :max="data['value']['total_limit']">
          <strong>{{ numberFormat((data['value']['total_rx'] + data['value']['total_tx']).toFixed(2)) }} / {{ numberFormat(data['value']['total_limit']) }} GB</strong>
        </progress-bar>
      </template>
      <template #cell(owner)="{data}">
        <a :href="data['item']['owner']['facebook_url']" class="font-weight-bold" target="_blank">{{ data['item']['owner']['facebook_name'] }}</a>
        <br/>
        <span v-if="data['item']['owner']['balance'] > 0" class="text-secondary">Số dư: {{ currencyFormat(data['item']['owner']['balance']) }}</span>
      </template>
      <template #cell(is_auto_renew)="{data}">
        <div class="w-100 text-center cursor-pointer">
          <i v-if="data['value']"
             class="fas fa-repeat-1 text-success fa-xl"
             v-b-tooltip.hover.v-success
             title="Tự động gia hạn đang bật"
          />
          <i v-else class="fas fa-repeat-1 text-secondary fa-xl"
             v-b-tooltip.hover.v-secondary
             title="Tự động gia hạn đang tắt"
          />
        </div>
      </template>
      <template #row-details="{data}">
        <ul v-for="(addon, index) in data['item']['addon']" :key="index">
          <li class="small">{{ addon['amount'] }} x <strong>{{ addon['name'] }}</strong></li>
        </ul>
      </template>
    </b-table-custom>

    <custom-modal id="cloud-vps-modal"
                  ref="cloud-vps-modal"
                  :title="currentModal.title"
                  :variant="currentModal.variant"
                  :ok-title="currentModal.okTitle"
                  :cancel-title="currentModal.cancelTitle"
                  :is-manual-close="currentModal.isManualClose"
                  @ok="currentModal.okAction"
    >
      <b-card-text v-if="modalName === 'modal-active'">
        Kích hoạt lại các VPS phía dưới đây?
      </b-card-text>
      <b-card-text v-if="modalName === 'modal-suspended'">
        Tạm ngưng các VPS phía dưới đây?
      </b-card-text>
      <b-card-text v-if="modalName === 'modal-delete'">
        Xoá các VPS phía dưới đây?
      </b-card-text>

      <item-list :items="dataObjectSelectedListClone">
        <template #item-label="{data}">
          {{ data['ip_port'] }} - {{ data['plan']['name'] }} ({{ data['owner']['full_name'] }})
        </template>
      </item-list>
    </custom-modal>

    <!-- update modal -->
    <custom-modal
        id="modal-update-vps"
        :title="$t('cloudVps.msgBox.title.update')"
        :ok-title="$t('cloudVps.msgBox.button.update')"
        :cancel-title="$t('cloudVps.msgBox.button.cancel')"
        variant="primary"
        @ok="updateCloudVps"
        v-if="dataObjectSelectedClone['plan'] !== undefined"
    >
      <b-card-text>
        <b-form-group>
          <label>Cloud VPS</label>
          <v-select v-model="dataObjectSelectedClone['lev_service_id']"
                    :clearable="false"
                    :reduce="cloudVps => cloudVps['lev_service_id']"
                    label="ip_port"
                    :options="cloudVpsNotActiveListClone"
          >
            <template #option="obj">
              <span><small><strong>{{ obj['ip_port'] }}</strong> ({{ obj['vm_plan_name'] }} - {{ obj['user_remark'] }})</small></span>
            </template>
            <template #selected-option="obj">
              <span><strong class="text-primary">{{ obj['ip_port'] }}</strong> ({{ obj['vm_plan_name'] }} - {{ obj['user_remark'] }})</span>
            </template>
          </v-select>
        </b-form-group>
        <b-form-group>
          <label>Plan</label>
          <v-select v-model="dataObjectSelectedClone['plan']['id']"
                    :clearable="false"
                    :reduce="plan => plan.id"
                    label="name"
                    :options="planList">
            <template #option="obj">
              <span><small><strong>{{ obj['name'] }}</strong> (<span class="text-success">{{ currencyFormat(obj['selling_price']) }}</span>)</small></span>
            </template>
            <template #selected-option="obj">
              <span><small><strong>{{ obj['name'] }}</strong> (<span class="text-success">{{ currencyFormat(obj['selling_price']) }}</span>)</small></span>
            </template>
          </v-select>
        </b-form-group>
        <b-row>
          <b-col md="6" cols="12">
            <custom-input v-model="dataObjectSelectedClone['username_default']" label="Tên đăng nhập" />
          </b-col>
          <b-col md="6" cols="12">
            <custom-input v-model="dataObjectSelectedClone['password_default']" label="Tên mật khẩu" />
          </b-col>
        </b-row>
        <b-form-group>
          <label>Owner</label>
          <v-select v-model="dataObjectSelectedClone['owner']['uuid']"
                    :reduce="owner => owner.uuid"
                    :clearable="false"
                    label="user_name"
                    :options="userList">
            <template #option="obj">
              <span><small><strong>{{ obj['user_name'] }}</strong> ({{ obj['facebook_name'] }} - {{ obj['email'] }})</small></span>
            </template>
            <template #selected-option="obj">
              <span><strong>{{ obj['user_name'] }}</strong> ({{ obj['facebook_name'] }} - {{ obj['email'] }})</span>
            </template>
          </v-select>
        </b-form-group>
        <b-form-group>
          <label>Co_Owner</label>
          <v-select v-model="dataObjectSelectedClone['co_owner']['uuid']"
                    :reduce="co_owner => co_owner.uuid"
                    :clearable="true"
                    label="full_name"
                    :options="userList">
            <template #option="obj">
              <span><small><strong>{{ obj['user_name'] }}</strong> ({{ obj['facebook_name'] }} - {{ obj['email'] }})</small></span>
            </template>
            <template #selected-option="obj">
              <span><strong>{{ obj['user_name'] }}</strong> ({{ obj['facebook_name'] }} - {{ obj['email'] }})</span>
            </template>
          </v-select>
        </b-form-group>
        <b-row>
          <b-col md="6" cols="12">
            <b-form-group label="Note">
              <b-form-textarea
                  rows="2"
                  v-model="dataObjectSelectedClone['note']"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" cols="12">
            <b-form-group label="Admin Note">
              <b-form-textarea
                  rows="2"
                  v-model="dataObjectSelectedClone['admin_note']"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <b-form-group>
              <label>Start Date</label>
              <flat-pickr
                  v-model="dataObjectSelectedClone['start_date']"
                  class="form-control"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group>
              <label>End Date</label>
              <b-input-group>
                <flat-pickr
                    v-model="dataObjectSelectedClone['end_date']"
                    class="form-control"
                />
                <b-input-group-append>
                  <b-button variant="success" @click="dataObjectSelectedClone['end_date'] = momentAdd(dataObjectSelectedClone['end_date'], 1, 'months')">
                    <font-awesome-icon icon="fa-solid fa-plus" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group>
          <div class="d-flex d-inline gap-1">
            <b-checkbox v-model="dataObjectSelectedClone['is_auto_renew']">Tự động gia hạn</b-checkbox>
            <b-checkbox v-model="dataObjectSelectedClone['is_livestream']">Server live stream</b-checkbox>
          </div>
        </b-form-group>
      </b-card-text>
    </custom-modal>
    <!-- addons modal -->
    <modal-form-validation
        ref="modal-addons"
        id="modal-addons"
        title="Cập Nhật Addons"
        :ok-title="$t('cloudVps.msgBox.button.update')"
        :cancel-title="$t('cloudVps.msgBox.button.cancel')"
        variant="primary"
        @submit="addonUpdate"
    >
      <template #content>
        <field-validation v-for="(addon, index) in addons" :key="index"
                          :id="index+'-addon'"
                          :name="addon['name']"
                          :label="`${addon['name']} (${currencyFormat(addon['selling_price'])})`"
                          type="number"
                          v-model="addon['amount']"
                          :placeholder="addon['name']"
                          icon-append="show"
                          rules="required|min:0|numeric"
        >
          <template #icon-append>
            <strong>UNIT</strong>
          </template>
        </field-validation>
      </template>
    </modal-form-validation>
  </div>
</template>

<script>

import BTableCustom from "@/views/components/table/BTableCustom.vue"
import planService from "@/mixins/utils/axios/plan.service";
import cloudVpsService from "@/mixins/utils/axios/cloudVps.service";

import inputHelper from '@/mixins/utils/inputHelper'
import dataRequester from '@/mixins/utils/dataRequester'
import dataObjectList from '@/mixins/utils/dataObjectList'
import BCardActions from "@core/components/b-card-actions/BCardActions"
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import CustomModal from "@/views/components/CustomModal"
import CustomInput from "@/views/components/form/CustomInput"
import FieldValidation from "@/views/components/form_validation/FieldValidation"
import ModalFormValidation from "@/views/components/form_validation/ModalFormValidation"
import NotificationHelper from "@/mixins/notificationHelper";
import ProgressBar from "@/views/components/static/ProgressBar.vue";
import userService from "@/mixins/utils/axios/user.service";
import LoadingButton from "@/views/components/LoadingButton.vue";
import ItemList from "@/views/components/ItemList.vue";

export default {
  name: "CloudVPS",
  components: {
    ItemList,
    LoadingButton,
    BCardActions,
    BTableCustom,
    CustomModal,
    CustomInput,
    FieldValidation,
    ModalFormValidation,
    vSelect,
    flatPickr,
    ProgressBar
  },
  mixins: [inputHelper, dataRequester, dataObjectList, NotificationHelper],
  computed: {
    currentModal () {
      let self = this
      switch (this.modalName) {
        case 'modal-active':
          return {
            title: this.$t('cloudVps.msgBox.title.active'),
            variant: 'success',
            okTitle: this.$t('cloudVps.msgBox.button.confirm'),
            cancelTitle: this.$t('cloudVps.msgBox.button.cancel'),
            isManualClose: false,
            okAction: function () {
              self.dataObjectSelectedListClone.forEach(x => {
                self.activeCloudVps(x)
              })
            }
          }
        case 'modal-suspended':
          return {
            title: this.$t('cloudVps.msgBox.title.suspended'),
            variant: 'danger',
            okTitle: this.$t('cloudVps.msgBox.button.confirm'),
            cancelTitle: this.$t('cloudVps.msgBox.button.cancel'),
            isManualClose: false,
            okAction: function () {
              self.dataObjectSelectedListClone.forEach(x => {
                self.suspendCloudVps(x)
              })
            }
          }
        case 'modal-delete':
          return {
            title: this.$t('cloudVps.msgBox.title.delete'),
            variant: 'danger',
            okTitle: this.$t('cloudVps.msgBox.button.confirm'),
            cancelTitle: this.$t('cloudVps.msgBox.button.cancel'),
            isManualClose: false,
            okAction: function () {
              self.dataObjectSelectedListClone.forEach(x => {
                self.deleteCloudVps(x)
              })
            }
          }
        default:
          return {
            title: '',
            variant: 'primary',
            okTitle: '',
            cancelTitle: '',
            isManualClose: false,
            okAction: function () {
            }
          }
      }
    }
  },
  data: function () {
    let self = this
    return {
      tableColumns: [
        {
          key: 'ip_port',
          label: this.$t('cloudVps.table.header.ip_port'),
          // formatter (value) {
          //   return `<a href="javascript:;" class="font-weight-bold">${value}</a>`
          // }
        },
        {
          key: 'plan',
          label: this.$t('cloudVps.table.header.plan'),
          formatter (value, index, item) {
            return `<strong class="text-info">${item['plan']['name']}</strong>
                    <br/>
                    <span class="text-success">${self.currencyFormat(item['price'] + item['addon'].map(x => x['price'] * x['amount']).reduce((accumulator, currentValue) => accumulator + currentValue, 0))}</span>`
          }
        },
        {
          key: 'username_default',
          label: 'Tên đăng nhập'
        },
        {
          key: 'password_default',
          label: 'Mật khẩu'
        },
        {
          key: 'region',
          label: this.$t('cloudVps.table.header.region')
        },
        {
          key: 'bandwidth',
          label: 'Băng thông',
        },
        {
          key: 'start_date',
          label: this.$t('cloudVps.table.header.start_date')
        },
        {
          key: 'end_date',
          label: this.$t('cloudVps.table.header.end_date')
        },
        {
          key: 'os',
          label: this.$t('cloudVps.table.header.os')
        },
        {
          key: 'owner',
          label: this.$t('cloudVps.table.header.owner'),
          // formatter (value) {
          //   if (value['uuid'] === undefined || value['uuid'] === null) {
          //     return null
          //   } else {
          //     return `<a href="${value['facebook_url']}" class="font-weight-bold" target="_blank">${value['full_name']}</a>`
          //   }
          // }
        },
        {
          key: 'co_owner',
          label: this.$t('cloudVps.table.header.co_owner'),
          formatter (value) {
            if (value['uuid'] === undefined || value['uuid'] === null) {
              return null
            } else {
              return `<a href="${value['facebook_url']}" class="font-weight-bold" target="_blank">${value['facebook_name']}</a>`
            }
          }
        },

        {
          key: 'is_auto_renew',
          label: 'Tự động gia hạn'
        },
        {
          key: 'note',
          label: this.$t('cloudVps.table.header.note')
        },
        {
          key: 'admin_note',
          // label: this.$t('cloudVps.table.header.note')
        },
        {
          key: 'payment_status',
          label: this.$t('cloudVps.table.header.payment_status')
        },
        {
          key: 'operation_status',
          label: this.$t('cloudVps.table.header.operation_status')
        },
        {
          key: 'menu',
          label: this.$t('cloudVps.table.header.manage')
        }
      ],
      isDataLoading: true,
      co_owners: null,
      userKeywordSearch: '',
      isUserKeywordSearching: false,

      planTypeList: [],
      planList: [],
      userList: [],
      addons: [{
        name: '',
        amount: 0
      }],
      cloudVpsNotActiveList: [],
      cloudVpsNotActiveListClone: [],
      modalName: '',
      actionMenuList: [
        {
          name: 'button-renew',
          label: 'Gia hạn',
          icon: 'fas fa-calendar-plus',
          color: 'success'
        },
        {type: 'divider'},
        {
          name: 'button-start-multiple',
          label: 'Bật VPS',
          icon: 'fas fa-play',
          color: 'success'
        },
        {
          name: 'button-stop-multiple',
          label: 'Tắt VPS',
          icon: 'fas fa-stop',
          color: 'danger'
        },
        {
          name: 'button-restart-multiple',
          label: 'Khởi động lại',
          icon: 'fas fa-redo',
          color: 'warning'
        },
        {type: 'divider'},
        {
          name: 'modal-cancel',
          label: 'Hủy gia hạn',
          icon: 'fas fa-ban',
          color: 'secondary',
          disabled: true
        },
        {
          name: 'modal-revoke-cancel',
          label: 'Hoàn tác hủy',
          icon: 'fas fa-undo',
          color: 'success',
          disabled: true
        },
        {
          type: 'divider'
        },
        {
          name: 'modal-suspended',
          label: 'Tạm ngưng',
          icon: 'fas fa-ban',
          color: 'danger',
        },
        {
          name: 'modal-active',
          label: 'Kích hoạt',
          icon: 'fas fa-lock-open',
          color: 'success',
        },
        {
          name: 'modal-delete',
          label: 'Xoá',
          icon: 'fa-solid fa-trash',
          color: 'danger'
        }
      ],
    }
  },
  created () {
    let self = this

    this.runApi(cloudVpsService.listAllActiveCloudVPS(), function (result) {
      self.dataObjectList = result.data.sort((a, b) => a['end_date'].localeCompare(b['end_date']));
    }, null, function () {
      self.isDataLoading = false
    })
    this.runApi(planService.getPlans(), function (result) {
      self.planList = result.data.filter(item => item['is_active'])
    })
    this.runApi(planService.listPlanType(), function (result) {
      self.planTypeList = result.data
    })
    this.runApi(userService.listAllUsers(), function (result) {
      self.userList = result.data
    })
    this.runApi(cloudVpsService.listAllNotActiveCloudVPS(), function (result) {
      self.cloudVpsNotActiveList = result.data
    })
  },
  methods: {
    selectedItemOnChange(selectedItems) {
      this.dataObjectSelectedList = selectedItems
    },
    openModal (modalName, data) {
      this.modalName = modalName

      if (modalName === 'button-renew') {
        this.renew()
        return
      } else if (modalName === 'button-start-multiple') {
        this.dataObjectSelectedList.forEach(x => {
          this.startVPS(x)
        })
        return
      } else if (modalName === 'button-stop-multiple') {
        this.dataObjectSelectedList.forEach(x => {
          this.stopVPS(x)
        })
        return
      } else if (modalName === 'button-restart-multiple') {
        this.dataObjectSelectedList.forEach(x => {
          this.restartVPS(x)
        })
        return
      }

      if (data !== undefined) {
        this.dataObjectSelected = data
        this.dataObjectSelectedClone = {...data}
        this.dataObjectSelectedList = [data].flat()
        this.dataObjectSelectedListClone= [...this.dataObjectSelectedList]
      } else {
        this.dataObjectSelectedList = this.$refs['tableData'].getSelectedData()
        this.dataObjectSelectedListClone= [...this.dataObjectSelectedList]
      }

      if (this.dataObjectSelectedList.length === 0) {
        this.popupError('Vui lòng chọn ít nhất 1 dòng dữ liệu')
        return
      }

      this.$nextTick(() => {
        this.$refs['cloud-vps-modal'].show()
      })
    },
    closeModal() {
      this.$refs['cloud-vps-modal'].hide()
    },
    cloudVpsMenuClick (rowData) {
      this.dataObjectSelected = rowData
      this.dataObjectSelectedClone = {...rowData}
      this.cloudVpsNotActiveListClone = [...this.cloudVpsNotActiveList]
      this.cloudVpsNotActiveListClone.push({
            lev_service_id: rowData['lev_service_id'],
            ip_port: rowData['ip_port'],
            user_remark: rowData['lev_remark'],
            vm_plan_name: rowData['plan']['name']
          }
      )
    },

    checkOperationStatus (rowData) {
      rowData['operation_status'] = 'Checking'
      this.runApi(cloudVpsService.getCloudVpsOperationStatus(rowData['id']), function (result) {
        rowData['operation_status'] = result.data['operation_status']
      }, function () {
        rowData['operation_status'] = 'Unknown'
      })
    },
    startVPS (rowData) {
      const self = this
      self.changeOperationStatus(rowData, 'Starting')
      self.runApi(cloudVpsService.startVPS(rowData['id']), function (result) {
            self.changeOperationStatus(rowData, result.data['operation_status'])
          }, function () {
            self.changeOperationStatus(rowData, 'Unknown')
          }, null,
          true,
          `${rowData['ip_port']}: ${self.$t('cloudVps.notification.start_successfully')}`)
    },
    stopVPS (rowData) {
      const self = this
      self.changeOperationStatus(rowData, 'Stopping')
      self.runApi(cloudVpsService.stopVPS(rowData['id']), function (result) {
            self.changeOperationStatus(rowData, result.data['operation_status'])
          }, function () {
            self.changeOperationStatus(rowData, 'Unknown')
          }, null,
          true,
          `${rowData['ip_port']}: ${self.$t('cloudVps.notification.stop_successfully')}`)
    },
    restartVPS (rowData) {
      const self = this
      self.changeOperationStatus(rowData, 'Restarting')
      self.runApi(cloudVpsService.restartVPS(rowData['id']), function (result) {
            self.changeOperationStatus(rowData, result.data['operation_status'])
          }, function () {
            self.changeOperationStatus(rowData, 'Unknown')
          }, null,
          true,
          `${rowData['ip_port']}: ${self.$t('cloudVps.notification.restart_successfully')}`)
    },
    deleteCloudVps (rowData) {
      const self = this
      self.changeOperationStatus(rowData, 'Deleting')
      self.runApi(cloudVpsService.deleteCloudVps(rowData['id']), function () {
            self.dataObjectList = self.dataObjectList.filter(x => x['id'] !== rowData['id'])
          }, function () {
            self.changeOperationStatus(rowData, 'Error')
          }, null,
          true,
          `${rowData['ip_port']}: ${self.$t('cloudVps.notification.delete_successfully')}`)
    },
    suspendCloudVps (rowData) {
      const self = this
      self.changeOperationStatus(rowData, 'Suspending')
      self.runApi(cloudVpsService.suspendCloudVps(rowData['id']), function () {
            self.setItemProperty(rowData, 'is_suspended', true)
            self.changeOperationStatus(rowData, 'Suspended')
          }, function () {
            self.changeOperationStatus(rowData, 'Error')
          }, null,
          true,
          `${rowData['ip_port']}: ${self.$t('cloudVps.notification.suspend_successfully')}`)
    },
    activeCloudVps (rowData) {
      const self = this
      self.changeOperationStatus(rowData, 'Activating')
      self.runApi(cloudVpsService.activeCloudVps(rowData['id']), function () {
            self.setItemProperty(rowData, 'is_suspended', false)
            self.changeOperationStatus(rowData, 'Running')
          }, function () {
            self.changeOperationStatus(rowData, 'Error')
          }, null,
          true,
          `${rowData['ip_port']}: ${self.$t('cloudVps.notification.active_successfully')}`)
    },
    updateCloudVps () {
      const self = this
      this.updateRowData(function (rowData) {
        self.changeOperationStatus(rowData, 'Updating')
        self.runApi(cloudVpsService.updateCloudVps({
              id: self.dataObjectSelectedClone['id'],
              lev_service_id: self.dataObjectSelectedClone['lev_service_id'],
              plan_id: self.dataObjectSelectedClone['plan']['id'],
              username_default: self.dataObjectSelectedClone['username_default'],
              password_default: self.dataObjectSelectedClone['password_default'],
              owner_uuid: self.dataObjectSelectedClone['owner']['uuid'],
              co_owner_uuid: self.dataObjectSelectedClone['co_owner']['uuid'],
              note: self.dataObjectSelectedClone['note'],
              admin_note: self.dataObjectSelectedClone['admin_note'],
              start_date: self.dataObjectSelectedClone['start_date'],
              end_date: self.dataObjectSelectedClone['end_date'],
              is_livestream: self.dataObjectSelectedClone['is_livestream'],
              is_auto_renew: self.dataObjectSelectedClone['is_auto_renew']
            }), function (result) {
              for (const key of Object.keys(result.data)) {
                self.setItemProperty(rowData, key, result.data[key])
              }
              self.changeOperationStatus(rowData, 'Success')
            }, function () {
              self.changeOperationStatus(rowData, 'Error')
            }, null,
            true)
      })
    },

    modalAddonOpen () {
      let self = this
      this.$loading.show()
      self.updateRowData(function (rowData) {
        self.runApi(cloudVpsService.getAddonList(rowData['id']), function (result) {
          self.addons = result.data
          self.$refs['modal-addons'].show()
        })
      })
    },
    addonUpdate () {
      let self = this
      this.updateRowData(function (rowData) {
        self.changeOperationStatus(rowData, 'Updating')
        self.runApi(cloudVpsService.addAddonActive(
            {
              id: rowData['id'],
              addons: self.addons.map(x => {
                return {
                  addon_id: x['id'],
                  amount: x['amount']
                }
              })
            }
        ), function () {
          self.changeOperationStatus(rowData, 'Success')
        }, function () {
          self.changeOperationStatus(rowData, 'Error')
        }, null, true)
      })
    },

    renew () {
      if (this.dataObjectSelectedList.length === 0) {
        this.popupError('Vui lòng chọn ít nhất 1 dịch vụ để gia hạn')
        return
      }

      let uniqueOwners = [...new Set(this.dataObjectSelectedList.map(x => x['owner']['uuid']))]
      let hasCancelledService = this.dataObjectSelectedList.some(x => x['payment_status'] === 'cancel')

      if (uniqueOwners.length > 1) {
        this.popupError('Không thể chọn nhiều người dùng khác nhau để gia hạn')
        return
      } else if (hasCancelledService) {
        this.popupError('Không thể chọn dịch vụ đã hủy để gia hạn')
        return
      }


      let url = this.$router.resolve({
        name: 'invoice-preview',
        params: {
          type: 'renew'
        },
        query: {
          id: this.dataObjectSelectedList.map(x => x['id']),
          category: 'vps'
        }
      })
      window.open(url.href, '_blank')
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
